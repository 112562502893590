import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c(VOverlay,[_c(VCard,{staticClass:"mx-auto",attrs:{"color":"white","max-width":"300"}},[_c(VSystemBar,{attrs:{"color":"pink darken-2","dark":""}}),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":"pink darken-2"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,[_vm._v("Technical Difficulties...")]),_c(VCardSubtitle,[_vm._v(" A reliable internet connection is required. If it is not an internet connection issue, pls delete your cookies for tutoredbrAIn.com. If issue persists, pls send me a text at 469-525-0450. ")])],1)]),_c(VBtn,{staticClass:"ml-4 my-5",attrs:{"outlined":"","rounded":"","small":""},on:{"click":_vm.closeErrorDialog}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }