<template>
  <v-overlay v-if="showDialog">
    <v-card color="white" max-width="300" class="mx-auto">
      <v-system-bar color="pink darken-2" dark> </v-system-bar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card color="pink darken-2">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title>Technical Difficulties...</v-card-title>

                  <v-card-subtitle>
                    A reliable internet connection is required. If it is not an internet connection issue, pls delete your cookies for tutoredbrAIn.com. If issue persists, pls send me a text at 469-525-0450.
                  </v-card-subtitle>
                </div>
              </div>
              <v-btn @click="closeErrorDialog" class="ml-4 my-5" outlined rounded small> OK </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    showDialog: true,
  }),
  methods: {
    closeErrorDialog() {
      this.showDialog = false
      this.$emit("OK")
    }
  },
};
</script>
